<template>
    <img :src="qrData" />
</template>

<script setup>
import QRCode from "qrcode";
import { onMounted, ref } from "vue";

const qrData = ref();

onMounted(() => {
    QRCode.toDataURL(window.location.href, {type:"svg", errorCorrectionLevel: "L"}, (error, url)=>{
        if(error) console.log("QR Code Error", error);
        else qrData.value = url;
    })
})

</script>