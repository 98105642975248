<template>
    <div v-if="!getReady"
        class="loading-layer flex flex-row items-center justify-center bg-slate-400 fixed left-0 top-0 w-full z-50">
        <img src="@/assets/loading.svg" class="w-16" />
    </div>
</template>

<script setup>
import { computed, defineProps } from 'vue';

const props = defineProps({
    getReady: {
        type: Boolean,
        default: false
    }
});

const getReady = computed(()=> props.getReady || false);
</script>

<style scoped lang="scss">
    .loading-layer {
        height: 100dvh;
    }
</style>